// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import Rails from "rails-ujs";
import "./set_time_zone";
import "styles/application"; // Import CSS
import "regenerator-runtime/runtime"
import "@hotwired/turbo-rails"

require.context("../images", true);

Rails.start();
